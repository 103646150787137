const contactData = [
	{platform: "Instagram", username: "kaarekamal", link: "https://www.instagram.com/kaarekamal/", icon: ""},
	{platform: "Facebook", username: "kaarekamaal", link: "https://www.facebook.com/kaarekamaal/", icon: ""},
	{platform: "Twitter", username: "kaar_e_kamaal", link: "https://twitter.com/kaar_e_kamaal?s=08", icon: ""},
	{platform: "Youtube", username: "Kaarekamal", link: "https://www.youtube.com/channel/UCVG24AlgpUsYWdSludvXhlA", icon: ""},
	{platform: "Linked In", username: "kaar-e-kamal", link: "https://www.linkedin.com/posts/kaar-e-kamal_kamalians-activity-6713422163644297217-qCaa", icon: ""},
	{platform: "Threads", username: "@kaarekamal", link: "https://www.threads.net/@kaarekamal", icon: ""},
	{platform: "Tiktok", username: "@kaar_e_kamal_official", link: "tiktok.com/@kaar_e_kamal_official", icon: ""},
];
export default contactData;
