import hndImage from "assets/img/cities/hnd.jpeg";
import bwpImage from "assets/img/cities/bwp.jpg";
import kmkImage from "assets/img/cities/kmk.jpg";
import isbImage from "assets/img/cities/isb.jpeg";
import lhrImage from "assets/img/cities/lhr.png";

const citiesData = [
	{
		id: "LHE",
		name: "Lahore",
		image: lhrImage,
		core: [
			{role: "President", name: "Rana Bilal Yousaf"},
			{role: "Vice President", name: "Ahmed Aamir"},
			{role: "General Secretary", name: "Usama Rafiq"},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: "Aqib Jawad"},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "RYK",
		name: "Rahim Yar Khan",
		image: "",
		core: [
			{role: "President", name: "Sannan Arshad"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: "Abubakar"},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "GRW",
		name: "Gujranwala",
		image: "",
		core: [
			{role: "President", name: "Muneeb Tariq"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: "Komal Yasmeen"},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "BWP",
		name: "Bahawalpur",
		image: bwpImage,
		core: [
			{role: "President", name: "Abdullah Saleem"},
			{role: "Vice President", name: "Arham Zafar"},
			{role: "General Secretary", name: "Asad Jameel"},
			{role: "Director Operations", name: "Saif Ur Rehman"},
			{role: "Female Team Lead", name: "Saman Mehak"},
			{role: "Director General", name: "Huzaifa Iftikhar"},
			{role: "Treasurer", name: "Laiba Nadeem"},
			{role: "Director Media", name: "Bakhtawar Shuja"},
			{role: "Director PR", name: "Shahina Abbasi"},
			{role: "Director Events", name: "Usama Safdar"},
			{role: "Director Sponsorship", name: "Sohail Ashraf"},
			{role: "Director Blood Donations", name: "Waqar Ahmed"},
			{role: "Director Documentation", name: "Fatimah Munir"},
			{role: "Director Social Media", name: "Syed Abdullah"},
			{role: "Director Inductions", name: "Shahreen Malik"},
		],
	},

	{
		id: "ISB",
		name: "Islamabad",
		image: isbImage,
		core: [
			{role: "President", name: "M. Daniyal Shahid"},
			{role: "Vice President", name: "Zoya Arooj"},
			{role: "General Secretary", name: "M. Talha ilyas"},
			{role: "Director Operations", name: "Muhammad Faisal"},
			{role: "Female Team Lead", name: "Shehla Saghir"},
			{role: "Director General", name: "Ali Affaq"},
			{role: "Treasurer", name: "Kashaf toheed"},
			{role: "Director Media", name: "Uzair Kabeer"},
			{role: "Director PR", name: "Fahad Satti"},
			{role: "Director Events", name: "Jawad Ashraf"},
			{role: "Director Sponsorship", name: "M. Amanullah"},
			{role: "Director Blood Donations", name: "Hammad Ali"},
			{role: "Director Documentation", name: "Abdul Rahman"},
			{role: "Director Social Media", name: "Ammar Mukhtar "},
			{role: "Director Inductions", name: "Kashaf Nazir"},
		],
	},

	{
		id: "MUL",
		name: "Multan",
		image: "",
		core: [
			{role: "President", name: "Qamar Mohyouddin"},
			{role: "Vice president", name: "Saweera Akram"},
			{role: "General secretary", name: "Qamar Mohyouddin"},
			{role: "Female team lead", name: "arooj fatima"},
			{role: "Director of operations", name: "Iram shahid butt"},
			{role: "Director finance", name: "Irfa choudhary"},
			{role: "Director social media", name: "Engr Shahid Bhutta"},
			{role: "Director General", name: "Saif ur rehman"},
			{role: "Director PR", name: "novera Ismail"},
			{role: "Director Media", name: "Shahid khan minzai"},
			{role: "Director Inductions", name: "Talha latif"},
			{role: "Director Events", name: "saaif ul Rehman"},
			{role: "Director Sponsorship", name: "Usman khan"},
			{role: "Director Documentation", name: "Muhammad Tufail"},
			{role: "Director BDS", name: "Moeen saqi"},
		],
	},

	{
		id: "CWN",
		name: "Chichawatni",
		image: isbImage,
		core: [
			{role: "President", name: "Hanzla Naveed"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "HND",
		name: "Haroonabad",
		image: hndImage,
		core: [
			{role: "President", name: "Arslan Maqbool"},
			{role: "Vice President", name: "Numman Sharif"},
			{role: "General Secretary", name: "Hafiz Muzammil"},
			{role: "Director Operations", name: "Muhammad Abdullah Ch"},
			{role: "Female Team Lead", name: "Hajra Sarwar"},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: "Talha Mukhtar"},
			{role: "Director Media", name: "Hamza Naveed"},
			{role: "Director PR", name: "Ahad Israa"},
			{role: "Director Events", name: "Zain Ali Sakhi"},
			{role: "Director Sponsorship", name: "Usama Ayub"},
			{role: "Director Blood Donations", name: "Abid Hussain"},
			{role: "Director Documentation", name: "Iqra Khalid"},
			{role: "Director Social Media", name: "Faizan Khalid"},
			{role: "Director Inductions", name: "Zafar Sukhera"},
		],
	},

	{
		id: "FSB",
		name: "Faislabad",
		image: isbImage,
		core: [
			{role: "President", name: "M Muzammil"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: " "},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "BWN",
		name: "Bhawalnagar",
		image: isbImage,
		core: [
			{role: "President", name: "Khizar Ashfaq"},
			{role: "Vice President", name: "Bilal Riaz"},
			{role: "General Secretary", name: "Juniad Akhtar"},
			{role: "Director Operations", name: "Hashir Ali"},
			{role: "Female Team Lead", name: "Guriya Jutt"},
			{role: "Director General", name: "Khubaib Ahmad"},
			{role: "Treasurer", name: "Ibrar Ahmad "},
			{role: "Director Media", name: "Hamza Malik"},
			{role: "Director PR", name: "Abubakar Moiz"},
			{role: "Director Events", name: "ihtisham jutt"},
			{role: "Director Sponsorship", name: "Haseeb Malik"},
			{role: "Director Blood Donations", name: "Talha Razzaq"},
			{role: "Director Documentation", name: "Ayesha Tariq "},
			{role: "Director Social Media", name: "Wania Riaz"},
			{role: "Director Inductions", name: "Zarish"},
		],
	},

	{
		id: "SKL",
		name: "Shiekhpura",
		image: isbImage,
		core: [
			{role: "President", name: "Ali"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "OKR",
		name: "Okara",
		image: isbImage,
		core: [
			{role: "President", name: "Maratab"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MRK",
		name: "Mureedke",
		image: isbImage,
		core: [
			{role: "President", name: "Khizar Sardar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KSR",
		name: "Kasur",
		image: isbImage,
		core: [
			{role: "President", name: "M Ahmed Shafique"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "SKT",
		name: "Sialkot",
		image: isbImage,
		core: [
			{role: "President", name: "Ezza Sikandar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MBN",
		name: "Mandi bahaudin",
		image: isbImage,
		core: [
			{role: "President", name: "Waleed Zafar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KHR",
		name: "Khariyan",
		image: isbImage,
		core: [
			{role: "President", name: "Umer"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "JHN",
		name: "Jhang",
		image: isbImage,
		core: [
			{role: "President", name: "Idrees Ajmal"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "ARF",
		name: "Arif Wala",
		image: isbImage,
		core: [
			{role: "President", name: "Meeral Maqbool"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "BKR",
		name: "Bhakar",
		image: isbImage,
		core: [
			{role: "President", name: "Khansa Urooj"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MKJN",
		name: "Mamukanjaan",
		image: isbImage,
		core: [
			{role: "President", name: "M Amir Sajjad"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MWN",
		name: "Mianwali",
		image: isbImage,
		core: [
			{role: "President", name: "Rizwan Ahmed"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "VEH",
		name: "Vehari",
		image: isbImage,
		core: [
			{role: "President", name: "Abdullah Dildar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KHN",
		name: "Khanewal",
		image: isbImage,
		core: [
			{role: "President", name: "Maarij Ali"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: " "},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "MBN",
		name: "Mandi bahaudin",
		image: isbImage,
		core: [
			{role: "President", name: "Waleed Zafar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "KMK",
		name: "Kamoke",
		image: kmkImage,
		core: [
			{role: "President", name: "Mubara Sajjad"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "QTA",
		name: "Quetta",
		image: kmkImage,
		core: [
			{role: "President", name: "Malik Moiz Asghar"},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	{
		id: "SWL",
		name: "Sahiwal",
		image: kmkImage,
		core: [
			{role: "President", name: ""},
			{role: "Vice President", name: ""},
			{role: "General Secretary", name: ""},
			{role: "Director Operations", name: ""},
			{role: "Female Team Lead", name: ""},
			{role: "Director General", name: ""},
			{role: "Treasurer", name: ""},
			{role: "Director Media", name: ""},
			{role: "Director PR", name: ""},
			{role: "Director Events", name: ""},
			{role: "Director Sponsorship", name: ""},
			{role: "Director Blood Donations", name: ""},
			{role: "Director Documentation", name: ""},
			{role: "Director Social Media", name: ""},
			{role: "Director Inductions", name: ""},
		],
	},

	

];

export default citiesData;
