import React from "react";

function ImageCard({id, name, image}) {
	return (
		<div className='card-gradient p-[1px] rounded-3xl hover:shadow-2xl cursor-pointer m-4 w-52 md:w-[345px] backdrop-blur-md '>
			<div className='rounded-3xl flex flex-col p-2 md:p-3 bg-white'>
				<img src={image} alt={name} className='rounded-2xl h-44 md:h-[350px] md:w-[310px] object-cover' />
				<div className='my-3 md:my-6 text-2xl'>{name}</div>
			</div>
		</div>
	);
}

export default ImageCard;
